@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;

    .feature-icon {
      width: 100px; /* Set the width of the image */
      height: 100px; /* Set the height of the image */
      object-fit: contain; /* Ensure the image fits within the given dimensions without distortion */
      margin-bottom: 15px; /* Space between image and text */
    }
    
  }
  #about,
  #services,
  #testimonials
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }


   /* WhatsApp button styling */
   .whatsapp-button {
    display: flex;
    align-items: center;
    background-color: #25D366; /* WhatsApp green color */
    color: rgb(0, 0, 0);
    padding: 10px 15px;
    border-radius: 5px;
    border-color: black;
    text-decoration: none;
    font-size: 16px;
    margin-top: 15px; /* Space above the button */
    position: fixed; /* Fixed positioning for better visibility */
    bottom: 20px; /* Position it at the bottom */
    right: 20px; /* Position it on the right side */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: add a shadow for better visibility */
    z-index: 1000; /* Ensure it is above other content */
  }

  .whatsapp-button i {
    margin-right: 8px;
  }
}
